<template>
  <div style="width: 960px;">
    <h4 class="form-title">{{ opts.formName }}</h4>

    <el-form ref="formRef" :inline="true" class="form-inline">
      <el-row>
        <el-col :span="24">
          <el-form-item label="公司:" prop="companyID" class="no-print">
            <el-tree-select ref="companys" v-model="forms.companyID" :data="companys" :disabled="!startNode" clearable
              @change="forms.departmentID = ''; companyChange()" check-strictly :render-after-expand="false"
              style="min-width:180px" />
          </el-form-item>
          <el-form-item label="部门:" prop="departmentID">
            <el-tree-select ref="departments" v-model="forms.departmentID" :data="departments" :disabled="!startNode"
              check-strictly :render-after-expand="false" style="min-width:120px" />
          </el-form-item>
          <el-form-item label="渠道:" prop="platformName">
            <el-select v-model="forms.platformName" style="min-width:150px" :disabled="!startNode" filterable
              :multiple-limit="1">
              <el-option v-for="dd in dict.platforms" :key="dd.value" :label="dd.label" :value="dd.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="时间:" prop="createDate" style="float:right;">
            {{ $util.fmtDate(opts.createDate, "yyyy年MM月dd日") }}
          </el-form-item>
          <el-form-item label="单号:" style="float:right;">
            {{ opts.billCode || '' }}
          </el-form-item>
          <div style="clear: both;"></div>
        </el-col>
      </el-row>

      <table class="form-table">
        <tr style="height:0px;">
          <td style="width:35%;"></td>
          <td style="width:10%;"></td>
          <td style="width:18%;"></td>
          <td></td>
        </tr>
        <tr>
          <td class="form-label">用 途</td>
          <td class="form-label">金 额</td>
          <td class="form-label" style="text-align:right">
            <div class="print-input">款项类型/货款批次：</div>
          </td>
          <td class="form-input">
            <el-select v-model="forms.feeType" filterable :disabled="!(startNode || archiveNode)">
              <el-option v-for="dd in dict.feeTypes" :key="dd.value" :label="dd.label" :value="dd.value" />
            </el-select>
          </td>
        </tr>
        <tr v-for="(item, index) in forms.details" :key="index">
          <td class="form-input">
            <el-input v-model="item.name" name="name" auto-complete="on" :disabled="!startNode"></el-input>
          </td>
          <td class="form-input">
            <el-input-number v-model="item.chargeFee" :min="0" :max="10000000" @change="calcSubtotal();"
              @focus="calcSubtotal();" :disabled="!startNode" placeholder="0.00" :precision="2"
              style="width:105px;"></el-input-number>
          </td>
          <td class="form-label" style="text-align:right">
            <span v-if="index == 0">收款名称：</span>
            <span v-if="index == 1">收款账号：</span>
            <span v-if="index == 2">银行开户行：</span>
          </td>
          <td class="form-input">
            <el-input v-if="index == 0" v-model="forms.bankUserName" style="max-width: 600px" placeholder="银行卡用户名"
              class="input-with-select" :disabled="!startNode">
              <template #append v-if="startNode">
                <el-select v-model="forms.bankIndex" placeholder="历史账户" style="width: 115px"
                  @change="suggestBankChange()" :disabled="!startNode">
                  <el-option v-for="dd in suggestBanks" :key="dd.index" :label="dd.label" :value="dd.index" />
                </el-select>
              </template>
            </el-input>
            <!-- <el-input v-if="index == 0" v-model="forms.bankUserName" name="bankUserName" auto-complete="on"
              :disabled="!startNode" placeholder="报销人的姓名"></el-input> -->
            <el-input v-if="index == 1" v-model="forms.bankAccount" name="bankAccount" auto-complete="on"
              :disabled="!startNode" placeholder="报销人的账户号码"></el-input>
            <el-input v-if="index == 2" v-model="forms.bankName" name="bankName" auto-complete="on"
              :disabled="!startNode" placeholder="报销人的账户所在银行"></el-input>
          </td>
        </tr>
        <tr>
          <td class="form-label">合 &nbsp; &nbsp; 计：</td>
          <td class="form-label">￥{{ forms.subtotal || "0.00" }}</td>
          <td class="form-input form-label" colspan="2">
            &nbsp; &nbsp;原借款：
            <el-input-number v-model="forms.originFee" :min="-10000000" :max="10000000" controls-position="right"
              @change="calcSubtotal" :disabled="!startNode" placeholder="0.00"
              style="text-align:left;max-width:100px;"></el-input-number>
            应退余额：
            <el-input-number v-model="forms.refundFee" :min="-10000000" :max="10000000" controls-position="right"
              disabled placeholder="0.00" style="text-align:left;max-width:100px;"></el-input-number>
          </td>
        </tr>
        <tr>
          <td class="form-input" colspan="4">
            <el-row :gutter="20">
              <el-col :span="3" :offset="3">金额大写：</el-col>
              <el-col :span="15">{{ bigFeeStr || "零" }}</el-col>
            </el-row>
          </td>
        </tr>
        <tr>
          <td class="form-input" colspan="4">
            <el-row :gutter="20">
              <el-col :span="3" :offset="3">领导审批：</el-col>
              <el-col :span="3">部门审批：</el-col>
              <el-col :span="3">报销人：</el-col>
              <el-col :span="3">财务审批：</el-col>
              <el-col :span="3">出纳：</el-col>
              <el-col :span="3">领款人：</el-col>
            </el-row>
          </td>
        </tr>
        <tr class="no-print">
          <td class="form-input" colspan="4" style="line-height:30px;">&nbsp;</td>
        </tr>
        <!-- v-if="payNode || archiveNode || endNode" -->
        <tr class="no-print">
          <td class="form-input" colspan="4">
            <table class="child-table" style="margin:10px;table-layout: fixed;">
              <tr style="height:0px;">
                <td style="width:25%;"></td>
                <td style="width:16%;"></td>
                <td style="width:15%;"></td>
                <td style="width:18%;"></td>
                <td style="width:15%;"></td>
                <td></td>
              </tr>
              <tr>
                <td class="form-label form-input form-center" colspan="6">
                  <b>额外填写项(申请人填写发票以及额外用途等信息)</b>
                </td>
              </tr>
              <tr>
                <td class="form-label">备 &nbsp; &nbsp; 注：</td>
                <td class="form-input" colspan="5">
                  <el-input v-model="forms.remark" :disabled="!startNode"></el-input>
                </td>
              </tr>
              <tr>
                <td class="form-label">付款时间：</td>
                <td class="form-label">
                  <el-date-picker v-model="forms.payDate" type="date" style="max-width:120px;" :disabled="startNode" />
                </td>
                <td class="form-input form-center">付款账户：</td>
                <td class="form-input" colspan="3">
                  <el-select v-model="forms.payBankInfo" style="max-width:150px" :disabled="startNode" filterable
                    :multiple-limit="1" @change="bankChange">
                    <el-option v-for="dd in dict.banks" :key="dd.value" :label="dd.label" :value="dd.value" />
                  </el-select>
                  {{ forms.payBankName }} - {{ forms.payCardNo }}
                </td>
              </tr>
              <tr>
                <td class="form-label">请款实际用途：</td>
                <td class="form-input" colspan="5">
                  <el-input v-if="!forms.purpose" v-model="forms.payPurpose" name="purpose"
                    auto-complete="on"></el-input>
                  <el-input v-else v-model="forms.purpose" name="purpose" auto-complete="on"></el-input>
                </td>
              </tr>
              <tr>
                <td class="form-label">是否有附加及类型：</td>
                <td class="form-input" colspan="2">
                  &nbsp;&nbsp;
                  <el-radio v-model="forms.payAddition" label="发票">发票</el-radio>
                  <el-radio v-model="forms.payAddition" label="收据">收据</el-radio>
                  <el-radio v-model="forms.payAddition" label="无">无</el-radio>
                </td>
                <td class="form-input form-center">是否有合同：</td>
                <td class="form-input" colspan="2">
                  &nbsp;&nbsp;
                  <el-radio v-model="forms.hasContract" label="是">是</el-radio>
                  <el-radio v-model="forms.hasContract" label="否">否</el-radio>
                </td>
              </tr>
              <tr>
                <td class="form-input form-center">发票号：</td>
                <td class="form-input">
                  <el-input v-model="forms.taxNo" style="max-width:130px;"></el-input>
                </td>
                <td class="form-input form-center">发票时间：</td>
                <td class="form-input">
                  <el-date-picker v-model="forms.taxDate" type="date" style="max-width:120px;" />
                </td>
                <td class="form-input form-center">发票价税金额：</td>
                <td class="form-input">
                  <el-input-number v-model="forms.taxDeduction" :min="0" :max="10000000" style="max-width:150px;"
                    placeholder="0.00"></el-input-number>
                </td>
              </tr>
              <tr>
                <td class="form-input form-center">凭证号：</td>
                <td class="form-input">
                  <el-input v-model="forms.voucherNo" style="width:150px;"></el-input>
                </td>
                <td class="form-label">发票内容：</td>
                <td class="form-input" colspan="3">
                  <el-input v-model="forms.taxContent" style="max-width:300px;"></el-input>
                </td>
              </tr>
              <tr>
                <td class="form-label">多渠道分摊：</td>
                <td class="form-input" colspan="5">
                  <el-select v-model="forms.platformNames" multiple collapse-tags-tooltip style="width: 100%"
                    @change="platformChange" placeholder="未选择则默认为申请的渠道" filterable>
                    <el-option v-for="dd in dict.platforms" :key="dd.value" :label="dd.label" :value="dd.value" />
                  </el-select>
                </td>
              </tr>
              <tr v-for="(item, index) in forms.platforms" :key="index">
                <td class="form-input" style="font-size: 0.8em; text-align: right;">分摊渠道{{ index + 1 }}：</td>
                <td class="form-input form-center">
                  {{ item.name }}
                </td>
                <td class="form-label">分摊金额：</td>
                <td class="form-input">
                  <el-input-number v-model="item.fee" :min="0" :max="10000000" style="max-width:150px;"
                    placeholder="0.00" @change="platformChange"></el-input-number>
                </td>
                <td class="form-label">分摊比例：</td>
                <td class="form-input form-center">
                  {{ item.rate || 0 }} %
                </td>
              </tr>

              <tr>
                <td class="form-label">多部门分摊：</td>
                <td class="form-input" colspan="5">
                  <el-tree-select ref="departmentTree" v-model="forms.departmentIds" style="width: 100%"
                    @change="departmentChange" :data="departments" multiple collapse-tags-tooltip filterable
                    :render-after-expand="false" placeholder="未选择则默认为申请的部门" />
                </td>
              </tr>
              <tr v-for="(item, index) in forms.departments" :key="index">
                <td class="form-input" style="font-size: 0.8em; text-align: right;">分摊部门{{ index + 1 }}：</td>
                <td class="form-input form-center">
                  {{ item.name }}
                </td>
                <td class="form-label">分摊金额：</td>
                <td class="form-input">
                  <el-input-number v-model="item.fee" :min="0" :max="10000000" style="max-width:150px;"
                    placeholder="0.00" @change="departmentChange"></el-input-number>
                </td>
                <td class="form-label">分摊比例：</td>
                <td class="form-input form-center">
                  {{ item.rate || 0 }} %
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <!-- <el-button type="primary" @click="forms.details.push({})" :disabled="opts.status != 0">增加明细</el-button>
    <el-button @click="removeItem(index)" :disabled="opts.status != 0">删除</el-button> -->
    </el-form>

  </div>
</template>

<script>
import { getSuggestBank } from "@/api/workflow";
import { fetchBankList } from "@/api/fund";
export default {
  name: "expense",
  components: {},
  data() {
    return {
      initOpts: {
        formType: 'FYBX',
        formCode: 'expense',
        formName: '费用报销单',
        flowCode: 'flow-expense',
        status: 0,
      },
      forms: this.formdata,
      rules: {
        // bankInfo: [
        //     { required: true, message: "请输入要报销的银行账户信息", trigger: "blur" },
        // ],
        // desc: [
        //     { required: true, message: "请输入报销的原因及备注", trigger: "blur" },
        // ],
      },
      departments: [],
      companys: [],


      dict: {
        feeTypes: [],
        platforms: [],
        alias: [],
      },
      suggestBanks: [],
    };
  },
  props: {
    userinfo: {
      type: Object,
      default: {},
    },
    opts: {
      type: Object,
      default: {}
    },
    formdata: {
      type: Object,
      default: { details: [{}, {}, {}] },
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    getInitOpts() {
      return this.initOpts;
    },
    // getInitForms() {
    //   return this.initForms;
    // },
    formCheck() {
      if (!this.forms.companyID || !this.forms.departmentID) {
        this.$message({ type: "info", message: "请选择对应的公司和部门", });
        return false;
      }

      this.forms.companyName = this.$refs.companys.getNode(this.forms.companyID).data.companyName;
      this.forms.departmentName = this.$refs.departments.getNode(this.forms.departmentID).data.departmentName;

      if (!this.forms.platformName) {
        this.$message({ type: "info", message: "请选择报销的渠道平台", });
        return false;
      }

      if (!this.forms.bankUserName) {
        this.$message({ type: "info", message: "请填写收款银行账户名称", });
        return false;
      }
      if (!this.forms.bankAccount) {
        this.$message({ type: "info", message: "请填写收款银行账户编号", });
        return false;
      }
      if (!this.forms.bankName) {
        this.$message({ type: "info", message: "请填写收款银行信息", });
        return false;
      }
      if (!this.forms.feeType) {
        this.$message({ type: "info", message: "请填写报销的款项类型/货款批次信息", });
        return false;
      }

      if (this.forms.details.length == 0) {
        this.$message({ type: "info", message: "请填写至少一条报销明细", });
        return false;
      }
      let valid = false;
      this.forms.details.forEach(item => {
        if (item.name && item.chargeFee > 0) {
          valid = true;
        }
      });
      if (!valid) {
        this.$message({ type: "info", message: "请补充完善报销明细信息", });
        return false;
      }
      // this.$refs.forms.validate((valid) => {
      //   if (!valid) return;
      // });

      if (this.forms.platforms && this.forms.platforms.length > 0) {
        let platformSubtotal = this.forms.platforms.reduce((pre, cur) => { return pre + cur.fee }, 0);
        if (platformSubtotal > 0 && platformSubtotal.toFixed(2) != this.forms.subtotal) {
          this.$message({ type: "info", message: "各渠道分摊累计金额与总金额不一致", });
          return false;
        }
      }
      if (this.forms.departments && this.forms.departments.length > 0) {
        let departmentSubtotal = this.forms.departments.reduce((pre, cur) => { return pre + cur.fee }, 0);
        if (departmentSubtotal > 0 && departmentSubtotal.toFixed(2) != this.forms.subtotal) {
          this.$message({ type: "info", message: "各部门分摊累计金额与总金额不一致", });
          return false;
        }
      }

      //财务出纳节点 并且点击的是同意
      if (this.payNode && this.opts.status == '10') {
        if (!this.forms.payDate || !this.forms.payBankInfo) {
          this.$message({ type: 'info', message: '请填写本次报销出账的时间及付款账户' });
          return;
        }
      }
      //财务归档节点 并且点击的是同意
      if (this.archiveNode && this.opts.status == '10') {
        if (!this.forms.purpose && !this.forms.payPurpose) {
          this.$message({ type: 'info', message: '请填写请款的实际用途' });
          return;
        }
        if (!this.forms.payAddition) {
          this.$message({ type: 'info', message: '请填写是否有附加及类型' });
          return;
        }
        if (this.forms.payAddition != '无' && (!this.forms.taxNo || !this.forms.taxDate || !this.forms.taxDeduction)) {
          this.$message({ type: 'info', message: '请填写报销发票的详细信息(发票号/发票时间/价税金额)' });
          return;
        }
        if (!this.forms.hasContract) {
          this.$message({ type: 'info', message: '请填写是否有合同' });
          return;
        }
        if (!this.forms.voucherNo) {
          this.$message({ type: 'info', message: '请填写该单据的凭证号' });
          return;
        }
      }

      return true;
    },
    getForms() {
      if (!this.opts.formDesc || this.startNode) {
        this.opts.formDesc = '金额:' + (this.forms.subtotal || '') + ';';
        this.forms.details.forEach(item => {
          if ((item.name || '').length > 0)
            this.opts.formDesc += (item.name || '') + ';';
        });
      }
      if (this.bossNode) this.opts.formDesc = '授权时间:'
        + this.$util.fmtDate(new Date(), "yyyy-MM-dd HH:mm") + ';'
        + this.opts.formDesc;
      this.forms.bankAccount = this.forms.bankAccount.replace(/\s/g, "");
      return this.forms;
    },
    removeItem(index) {
      this.forms.details.splice(index, 1);
      console.log(this.forms.details)
    },
    companyChange() {
      if (!this.forms.companyID) return;

      var that = this;
      this.$kaung.departments(this.forms.companyID).then((res) => {
        that.departments = res;
        if (this.forms.companyID == this.companyID) {
          this.forms.departmentID = this.userinfo.departmentID;
        }
      });
    },
    calcSubtotal() {
      this.forms.subtotal = 0;
      this.forms.details.forEach(item => {
        this.forms.subtotal += item.chargeFee || 0;
      });
      this.forms.subtotal = this.forms.subtotal.toFixed(2);
      this.forms.refundFee = ((this.forms.originFee || 0) - this.forms.subtotal).toFixed(2);

      this.platformChange();
      this.departmentChange();
    },

    callback(ins) {
      console.log('callback', ins);
    },

    platformChange() {
      this.forms.platforms = this.forms.platforms || [];
      this.forms.platformNames.forEach((res) => {
        let items = this.forms.platforms.filter(item => item.name == res);
        if (items.length == 0) {
          this.forms.platforms.push({ "name": res, "fee": 0, "rate": 0 })
        } else {
          if (this.forms.subtotal > 0)
            items[0].rate = (items[0].fee / this.forms.subtotal * 100).toFixed(2);
        }
      });
      this.forms.platforms = this.forms.platforms.filter(item => this.forms.platformNames.indexOf(item.name) > -1);
    },
    departmentChange() {
      this.forms.departments = this.forms.departments || [];
      this.forms.departmentIds.forEach((departmentId) => {
        let departmentName = this.$refs.departmentTree.getNode(departmentId).data.departmentName;

        let items = this.forms.departments.filter(item => item.id == departmentId);
        if (items.length == 0) {
          this.forms.departments.push({ "id": departmentId, "name": departmentName, "fee": 0, "rate": 0 })
        } else {
          if (this.forms.subtotal > 0)
            items[0].rate = (items[0].fee / this.forms.subtotal * 100).toFixed(2);
        }
      });
      this.forms.departments = this.forms.departments.filter(item => this.forms.departmentIds.indexOf(item.id) > -1);
    },

    bankChange() {
      var cardInfos = this.dict.alias.filter(res => res.cardAlias == this.forms.payBankInfo);
      if (cardInfos && cardInfos.length > 0) {
        this.forms.payBankName = cardInfos[0].bankName;
        this.forms.payCardName = cardInfos[0].cardName;
        this.forms.payCardNo = cardInfos[0].cardNo;
      } else {
        this.forms.payBankName = '';
        this.forms.payCardName = '';
        this.forms.payCardNo = '';
      }
    },
    //获取这个节点的 建议银行
    getSuggestBank() {
      getSuggestBank(this.initOpts.flowCode).then((res) => {
        console.log(res);
        if (!res || !res.data || res.code != 200) return;
        this.suggestBanks = res.data;
        for (let i = 0; i < this.suggestBanks.length; i++) {
          let item = this.suggestBanks[i];
          item.index = i;
          item.label = '[' + item.bankUserName + ']' + item.bankName + "(" + item.bankAccount + ")";
        };
      });
    },
    suggestBankChange() {
      //console.log(this.forms.bankIndex);
      let bankItem = this.suggestBanks[this.forms.bankIndex];
      this.forms.bankUserName = bankItem.bankUserName;
      this.forms.bankAccount = bankItem.bankAccount;
      this.forms.bankName = bankItem.bankName;
    },
  },
  created() {
    let that = this;
    if (this.opts && this.opts.flowCode)
      this.initOpts = JSON.parse(JSON.stringify(this.opts));

    this.$kaung.companys().then((res) => {
      that.companys = res;
    });

    this.getSuggestBank();

    if (!this.$route.query.id) {
      this.forms.companyID = this.userinfo.companyID;
      this.companyChange();
      this.forms.departmentID = this.userinfo.departmentID;
    } else {
      this.companyChange();
    }

    this.$kaung.dictionary("workflow", "feeType").then((res) => {
      this.dict.feeTypes = res;
    });
    this.$kaung.dictionary("workflow", "platform").then((res) => {
      this.dict.platforms = res;
    });
    this.$kaung.dictionary("workflow", "banks").then((res) => {
      this.dict.banks = res;
    });
    fetchBankList().then((res) => {
      this.dict.alias = res.data;
    });
  },
  watch: {
    formdata: function (newValue, oldValue) {
      console.log(newValue);
    },
  },
  computed: {
    startNode() {
      //草稿或者退回状态
      if (this.initOpts.status == 0 || this.initOpts.status == -10 || this.initOpts.status == '')
        return true;
      return false;
    },
    endNode() {
      if (this.initOpts.status == 50) return true;
      return false;
    },
    payNode() {
      if (this.initOpts.nodeName && this.initOpts.nodeName.indexOf('出纳') > -1)
        return true;
      return false;
    },
    archiveNode() {
      //归档或者确认单据完整
      if (this.initOpts.nodeName && (this.initOpts.nodeName.indexOf('归档') > -1 || this.initOpts.nodeName.indexOf('单据') > -1))
        return true;
      return false;
    },
    bossNode() {
      if (this.initOpts.nodeName && (this.initOpts.nodeName.indexOf('总经理') > -1 || this.initOpts.nodeName.indexOf('董事长') > -1))
        return true;
      return false;
    },
    bigFeeStr() {
      return this.$util.toBigMoney(this.forms.subtotal || 0);
    },

    // cashierVisible() {
    //   return this.opts.status == '50' || this.opts.status == '-50' || (this.opts.status == '10' && (this.opts.nodeName || '').indexOf('出纳') > -1)
    // },
    // cashierEnabled() {
    //   if (this.opts.status == '50' || this.opts.status == '-50' || (this.opts.status == '10' && (this.opts.nodeName || '').indexOf('出纳') > -1))
    //     return this.opts.status == '50' || this.opts.status == '-50';

    //   return true;
    // }
  }

};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px;
}

.el-form--inline .el-form-item {
  margin-right: 10px;
}

:deep .el-select {
  vertical-align: initial;
}

:deep .el-input__wrapper,
:deep .el-textarea__inner,
:deep .el-select .el-input.is-focus .el-input__wrapper,
:deep .el-select:hover:not(.el-select--disabled) .el-input__wrapper {
  border: none;
  max-width: 100%;
  box-shadow: none !important;

  padding: 0px 8px;
}

:deep .el-select .el-input__inner {
  padding-top: 1px;
}

:deep .el-form-item__label {
  padding: 0;
}

:deep .el-input-number__increase,
:deep .el-input-number__decrease {
  display: none;
}

/* :deep .el-input__inner {
  text-align: left;
} */



.form-table {
  width: 98%;
}

.form-label {
  background: #fff;
  text-align: center;
  padding: 0px;
  font-size: 0.95em;
  font-weight: 500;
  vertical-align: middle;

  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.form-input {
  width: auto;
  line-height: 35px;
  font-size: 0.95em;
  border: 1px solid #ccc;
  text-align: left;
  vertical-align: middle;
}


.form-center {
  text-align: center;
}


.child-table {
  font-size: 0.9em;
}

.child-table .form-label,
.child-table .form-input {
  line-height: 30px;
  padding: 5px;
}

.el-tabs__content {
  min-height: 80vh;
}

:deep .el-input-number.is-controls-right .el-input__wrapper {
  padding: 0;
}
</style>